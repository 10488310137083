:root {
    --minWidth: 360px;
}

.layout-main-mobile {
    overflow: auto;
}

.ant-layout {
    background: #fff;
}

.layout-content {
    padding: 5px 5px 20px 5px;
}

.layout-header-mobile {
    z-index: 999;
    top: -10px;
    height: 70px;
    box-shadow: 0px 0px 8px 2px #000000;
}

    .layout-header-mobile .ant-col {
        align-content: center;
        align-self: center;
    }

.layout-header-desktop {
    z-index: 999;
    top: -10px;
    height: 120px;
    box-shadow: 0px 0px 8px 2px #000000;
}

    .layout-header-desktop .ant-col {
        align-content: center;
        align-self: center;
    }

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.layout-navmenu {
    font-size: 18px;
    position: relative;
    display: flex;
    justify-content: center;
}

.logo {
    width: 180px;
    padding: 12px;
    margin-left: 20px;
    border-radius: 15px;
    background-color: #fff;
    /*background-image: linear-gradient(#808080, #fff);*/
}

header.ant-layout-header.layout-header-mobile.sticky {
    background-image: linear-gradient(#108ee9,#081d25);
}

.ant-layout-header {
    /*background: #32B05A;*/
    background-image: linear-gradient(#29623b, #33D668);
}


    .ant-layout-header .ant-menu .ant-menu-item > span > a {
        color: #ffffff;
    }

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
}

/*    .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
        background-color: transparent;
    }*/

/*.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
}*/

.ant-layout-footer {
    min-width: var(--minWidth);
    background: #1f84ac;
    padding: 50px 100px;
    font-weight: bolder;
    box-shadow: 0px 0px 8px 2px #000000;
}

.footer-button {
    text-align: right;
    padding: 16px 8px;
    border-top: 1px solid #f0f0f0;
    margin: 0 !important;
    background: #ffffff;
}

.privacy-section {
    background: #136a8d;
    padding: 0px;
    bottom: 0px;
    position: fixed;
    display: block;
    width: 100vw;
}

.privacy-section-desktop {
    padding: 0px 200px;
}

.privacy-section-mobile {
    text-align: center;
}

.privacy-section div {
    color: #fff;
}

.privacy-section .ant-btn {
    color: #fff;
}


.back-top {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 14px;
}

.page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.page-sub-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.pointer:hover {
    cursor: pointer;
}

.card-grid-pagination-mobile {
    width: 100%;
}

.card-grid-pagination {
    top: 112px;
    width: 100%;
    background-color: white;
    z-index: 2;
}

.criteria {
    top: 115px;
    z-index: 2;
}

.card-grid {
    width: 100%;
    text-align: 'left',
}

.job-type-tag {
    width: 100%;
    padding: 12px;
    text-align: center;
}

.new-ad-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.green-button {
    background-color: #53b427;
    color: white;
}

.orange-button {
    background-color: #ffb14a;
    color: white;
}

.align-right {
    text-align: right;
    padding: 16px;
}

.align-center {
    text-align: center;
    padding: 16px;
}

.row-click {
    cursor: pointer;
}

.custom-modal {
    top: 30px;
    min-width: var(--minWidth);
}

    .custom-modal .ant-modal-body {
        max-height: calc( 100vh - 60px);
        overflow-x: auto;
    }

.action-buttons > div {
    padding: 8px
}

.custom-menu-item {
    padding: 8px 8px 8px 32px;
    background-color: white;
    display: block;
    max-height: 190px;
    overflow: auto;
}

    .custom-menu-item .ant-radio-group, .custom-menu-item .ant-radio-wrapper {
        font-size: 12px;
    }

.html-editor {
    height: 200px;
}

.page-padding {
    min-width: 660px;
    padding: 10px 50px 10px 50px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-left-padding {
    padding-left: 60px;
}


.cv-pool-criteria {
    background: #136a8d;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
}

.custom-hidden {
    display: none;
}


span.ant-dropdown-menu-title-content {
    font-size: 18px;
}
